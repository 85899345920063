import { Box, Button, CardHeader, FormControlLabel, IconButton, Paper, Select, Stack, TextField, Typography } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import moment from 'moment';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Checkbox } from "@mui/material";
import { MoreVertOutlined } from "@mui/icons-material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FlightService from "../../services/FlightService";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const timezoneOptions = [
	'America/New_York',
	'America/Chicago',
	'America/Denver',
	'America/Los_Angeles',
	'America/Anchorage',
	'Pacific/Honolulu',
];

const JobSettings = ({ jobSettings, onSave, onRun, tipContext, client, toggleJob, posId, reloadLogs }) => {
	const [openMore, setOpenMore] = useState(false);
	const moreElement = useRef(null);

	const [runDate, setRunDate] = useState(moment());
	const [sinceLastRun, setSinceLastRun] = useState(false);
	const [historySelected, setHistorySelected] = useState(false);
	const [loadFullInventory, setLoadFullInventory] = useState(false);
	const [skipInventory, setSkipInventory] = useState(false);
	const [onOrderOnly, setOnOrderOnly] = useState(false);
	const [monthsBack, setMonthsBack] = useState(1); // of months back to grab history

	const [jobData, setJobData] = useState(jobSettings);

	useEffect(() => {
		setJobData(jobSettings);
	}, [jobSettings]);

	useEffect(() => {
		if (!(runDate.month() == moment().month() && runDate.year() == moment().year())) {
			setSinceLastRun(false);
			setLoadFullInventory(false);
			setSkipInventory(false);
			setOnOrderOnly(false);
			setHistorySelected(true);
		} else {
			setHistorySelected(false);
		}
	}, [runDate]);

	const syncFromStagingToActuals = async () => {
		tipContext.show('Not supported yet.');
		return;
		setOpenMore(false);
		tipContext.showSuccess('Syncing from Staging to Actuals. This can take 30-60 seconds.');
		FlightService.syncFromStagingToActuals(client.Id).then((response) => {
			tipContext.showSuccess('Synced from Staging to Actuals');
		}).catch((error) => {
			console.log(error);
			tipContext.show('Error syncing from Staging to Actuals');
		});
	}

	const startHistoryLoad = async () => {
		if (monthsBack == '') {
			tipContext.show('Please select a number of months back');
			return;
		}
		try {
			// Create a promise that resolves or rejects based on the 15-second timeout or the API request
			const result = await Promise.race([
				FlightService.loadHistory(client.Id, monthsBack),
				new Promise((_, reject) =>
					setTimeout(() => reject(new Error('Request did not complete within 15 seconds')), 15000)
				)
			]);
		
			// If we reach here, it means the loadHistory completed successfully within 15 seconds
			tipContext.showSuccess('Starting history load...', 5);
		
			// Sleep for 15 seconds to wait for logs to show
			await new Promise(r => setTimeout(r, 15000));
			reloadLogs();
			tipContext.showSuccess('History grab is running. Logs will show up as progress is started.', 15);
		
		} catch (error) {
			console.log(error);
			tipContext.show(error.response?.data?.message || 'Error loading history');
		}			
	}

	const openDocumentation = () => {
		// confirm that user wants to open a new tab with doc
		if (window.confirm('Are you sure you want to open the documentation?')) {
			window.open('https://managementone.atlassian.net/wiki/x/BID4Rw', '_blank');
		}
	}

	return (
		<Paper elevation={2} sx={{mb:2}}>
			<Box sx={{mb:2}} spacing={2}>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<CardHeader
						title={'Flight Control Logs for ' + (client.ClientCode ?? '')}
						sx={{
							backgroundColor: 'white',
							'& .MuiTypography-root': {
									fontSize: '16px',
							},
							flexGrow: 1,
							borderBottom: '1px solid #ccc',
							fontWeight: 'bold',
						}}
					/>
					{(jobSettings != null && jobSettings != 'undefined' && jobSettings != '') ? (
						<>
							<IconButton onClick={openDocumentation}>
								<HelpOutlineIcon />
							</IconButton>
							<IconButton onClick={e => { setOpenMore(true) }} ref={moreElement}>
								<MoreVertOutlined sx={{
									color: 'black', 
								}} />
							</IconButton>
							<Menu
								open={openMore}
								anchorEl={moreElement.current}
								onClose={e => { setOpenMore(false) }}
							>
								<MenuItem dense onClick={() => syncFromStagingToActuals()}>Sync from Staging</MenuItem>
							</Menu>
						</>
					) : null}
				</div>
				{jobSettings ?
					<>
						<Box sx={{ mx: 2, mb: 2 }}>
							<Stack sx={{mb: 2}}>
								{posId == 1113 ?
									<FormControlLabel
										sx={{ ml: 1 }}
										control={
											<Checkbox
												checked={sinceLastRun}
												onChange={(e) => setSinceLastRun(e.target.checked)}
												color="primary"
												disabled={historySelected}
											/>
										}
										label="Data since last run only?"
									/>
								: null}
								{posId == 1113 ?
									<FormControlLabel
										sx={{ ml: 1 }}
										control={
											<Checkbox
												checked={loadFullInventory}
												disabled={historySelected}
												onChange={(e) => setLoadFullInventory(e.target.checked)}
												color="primary"
											/>
										}
										label="Load full inventory? (only for first time loads)"
									/>
								: null}
								{posId == 1113 ?
									<FormControlLabel
										sx={{ ml: 1 }}
										control={
											<Checkbox
												disabled={historySelected}
												checked={skipInventory}
												onChange={(e) => setSkipInventory(e.target.checked)}
												color="primary"
											/>
										}
										label="Skip Inventory?"
									/>
								: null}
								{posId = 1113 ? 
									<FormControlLabel
										sx={{ ml: 1 }}
										control={
											<Checkbox
												disabled={historySelected}
												checked={onOrderOnly}
												onChange={(e) => setOnOrderOnly(e.target.checked)}
												color="primary"
											/>
										}
										label="Grab only On Order?"
									/>
								: null}
							</Stack>
							<LocalizationProvider dateAdapter={AdapterMoment}>
								<DatePicker
									views={['year', 'month']}
									label="Run Date"
									minDate={moment('2000-01-01')}
									maxDate={moment('2100-01-01')}
									value={runDate}
									onChange={(newValue) => {
										setRunDate(newValue);
										if (newValue.month() !== moment().month()) {
											setSinceLastRun(false);
										}
									}}
									renderInput={(params) => <TextField {...params} />}
									inputFormat="MMM YYYY"
								/>
							</LocalizationProvider>
							<Button variant="contained" color="primary" sx={{ m: 1 }} onClick={() => onRun({period: runDate, sinceLastRun: sinceLastRun, loadFullInventory: loadFullInventory, skipInventory: skipInventory, onOrderOnly: onOrderOnly})}>Run</Button>
						</Box>
						{client.PointOfSaleId == 1113 ? 
							<>
								<Box
									sx={{
										px: 2,
										// py: 2,
										borderTop: '1px solid #ccc',
										fontWeight: 'normal',
									}}
								>
									<h3>
										Run History
									</h3>
								</Box>
								<Box
									sx={{ 
										px: 2,
										pt: 2,
										borderTop: '1px solid #ccc',
									}}
								>
									<TextField
										label="Months Back"
										select
										value={monthsBack}
										onChange={(e) => setMonthsBack(e.target.value)}
										SelectProps={{
											native: true,
										}}
										sx={{ width: 300 }}
									>
										<option value=""></option>
										{Array.from({ length: 24 }, (_, index) => (
											<option key={index + 1} value={index + 1}>
												{index + 1}
											</option>
										))}
									</TextField>
									<Button variant="contained" color="primary" sx={{ m: 1 }} onClick={() => startHistoryLoad()}>Get History</Button>
								</Box>
							</>
						: null}
						<Box
							sx={{
								mt: 2,
								px: 2,
								// py: 2,
								borderTop: '1px solid #ccc',
								fontWeight: 'normal',
							}}
						>
							<h3>
								Daily Run Settings
							</h3>
						</Box>
						<Box
							sx={{ 
								px: 2,
								pt: 2,
								borderTop: '1px solid #ccc',
							}}
						>
							<TextField
								label="Time Zone"
								select
								value={jobData.time_zone}
								onChange={(e) => setJobData({...jobData, 'time_zone': e.target.value})}
								SelectProps={{
									native: true,
								}}
								sx={{ width: 300 }}
							>
								<option value=""></option>
								{timezoneOptions.map((option) => (
									<option key={option} value={option}>
										{option}
									</option>
								))}
							</TextField>
							<Typography variant="body2" sx={{ ml: 1 }}>Schedule Time of Day for Client in CST. Leave empty to run with at normal 3:00AM CST with all other clients.</Typography>
							<Select
								label="Run Time (CST)"
								value={jobData.time_of_day}
								onChange={(e) => setJobData({...jobData, 'time_of_day': e.target.value})}
								sx={{ width: 300 }}
							>
								<MenuItem value={null}></MenuItem>
								<MenuItem value="01:00:00">1:00am</MenuItem>
								<MenuItem value="02:00:00">2:00am</MenuItem>
								<MenuItem value="04:00:00">4:00am</MenuItem>
								<MenuItem value="05:00:00">5:00am</MenuItem>
							</Select>
						</Box>
						<Box id="action-buttons" sx={{ m: 2 }}>
							<Button variant="contained" color="primary" sx={{ m: 1 }} onClick={() => onSave(jobData)}>Save Setings</Button>
							{jobSettings.enabled ?
								<Button variant="contained" color="primary" sx={{ m: 1 }} onClick={() => toggleJob(false)}>Disable Daily Run</Button> :
								<Button variant="contained" color="primary" sx={{ m: 1 }} onClick={() => toggleJob(true)}>Enable Daily Run</Button>
							}
						</Box>
					</>
				: <Button variant="contained" color="primary" sx={{ m: 1 }} onClick={() => toggleJob(true)}>Create Job Settings</Button>}
			</Box>
		</Paper>
	);
}

export default JobSettings;