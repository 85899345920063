import React from "react";
import './App.css';
import {
  Routes,
  Route,
  Outlet,
  useLocation,
  Navigate,
} from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme.js'
import AuthContext from './providers/AuthProvider'
import CurrentClientContext from './providers/CurrentClientProvider'
import Layout from './layouts/Layout'
import MainLayout from './layouts/MainLayout'
import GlobalTip from './providers/GlobalTip'
import NotFound from './pages/NotFound'
import NotLogin from './pages/NotLogin'
import Home from './pages/Home'
import Users from './pages/admin/Users'
import UserDetail from './pages/admin/UserDetail'
import UserRoles from './pages/admin/UserRoles'
import ClientSetup from './pages/admin/ClientSetup'
import Permission from './pages/admin/Permission'
import AuthMiddleware from './components/layout/AuthMiddleware'
import ClientMiddleware from './components/layout/ClientMiddleware'
import ClientProfile from './pages/client/ClientProfile'
import ImportClass from './pages/client/ImportClass'
import ClientMasterClassSetting from './pages/client/MasterClass'
import ClientLocations from './pages/client/ClientLocations'
import ClientClasses from './pages/client/ClientClasses'
import ClientCategories from './pages/client/ClientCategories'
import UploadData from './pages/client/UploadData'
import FlightControl from './pages/flight/FlightControl'
import BasePlan from "./pages/plan/BasePlan";
import LivePlanModify from "./pages/plan/LivePlanModify";
import CopyPlan from "./pages/plan/CopyPlan";
import CopyPlanUpload from "./pages/plan/CopyPlanUpload";
import CopyPlanFileDetail from "./pages/plan/CopyPlanFileDetail";
import {ConfirmDialogProvider} from './providers/ConfirmDialog';
import ClientTags from './pages/client/ClientTags';
import ForgotPassword from "./pages/ForgotPassword";
import RestorePassword from "./pages/RestorePassword";
import ClientPosMapping from "./pages/client/ClientPosMapping";
import ClientKPI from "./pages/client/ClientKPI";
import ClientAnalytics from "./pages/client/ClientAnalytics";
import ClientPlanPreview from "./pages/client/ClientPlanPreview";
import ClientReportLibrary from "./pages/client/ClientReportLibrary";
import PermissionMiddleware from "./components/layout/PermissionsMiddleware.js";
// import ClientSync from "./pages/admin/ClientSync";
import ClientAdd from "./pages/admin/ClientAdd.js";
import LightspeedAuthLanding from "./pages/lightspeed/LightspeedAuthLanding.js";
import DevTools from "./pages/dev-tools/DevTools.js";
import CustomCalendarManagement from "./pages/admin/CustomCalendarManagement.js";
import { HotKeysContext } from "./providers/HotKeysProvider.js";
import OtherPlanTools from "./pages/plan/OtherPlanTools.js";

// Integrations
import { init } from '@amplitude/analytics-browser';

// Init amplitude one time
if (process.env.REACT_APP_AMPLITUDE_API_KEY) {
  if (process.env.REACT_APP_APP_ENV !== 'production') {
    // init without tracking
    init(process.env.REACT_APP_AMPLITUDE_API_KEY, { autocapture: false });
  } else {
    init(process.env.REACT_APP_AMPLITUDE_API_KEY);
  }
}


/**
 * The main component of the application.
 * @returns {JSX.Element} The rendered App component.
 */
function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
      <AuthContext.Provider>
      <ConfirmDialogProvider>
      <GlobalTip.Provider>
      <HotKeysContext.Provider>
      <CurrentClientContext.Provider>
        <Routes>
          <Route element={<Layout />} >
            <Route path="/login" element={<NotLogin />}/>
            <Route path="/forgot-password" element={<ForgotPassword />}/>
            <Route path="/restore-password/:code" element={<RestorePassword />}/>
          </Route>

          <Route element={<MainLayout />} >
            <Route element={<AuthMiddleware />} >
              <Route path="/" element={<Home />} />
              <Route path="/users" element={<Users />} />
              <Route path="/users/:user_id" element={<UserDetail />} />
              <Route path="/admin/user-roles" element={<UserRoles />} />
              <Route path="/admin/permissions" element={<Permission />} />
              <Route path="/admin/flightcontrol" element={<FlightControl />} />
              {/* The permissions check for role-create maybe should be replaced with a role based check for admin/support */}
              <Route element={<PermissionMiddleware permissions={['role-create']} />}>
                <Route path="/plan/copy" element={<CopyPlan />} />
                <Route path="/plan/copy/upload" element={<CopyPlanUpload />} />
                <Route path="/plan/copy/file/:id" element={<CopyPlanFileDetail />} />
                <Route path="/plan/copy/line/:id" element={<CopyPlanFileDetail mode="line"/>} />
                <Route path="/admin/custom-calendar-management" element={<CustomCalendarManagement />} />
              </Route>
              <Route element={<PermissionMiddleware permissions={['add-client']} />}>
                  <Route path="/client/setup" element={<ClientSetup />} />
                  <Route path="/client/add" element={<ClientAdd />} />
              </Route>
              <Route element={<PermissionMiddleware permissions={['view-client-info']} />}>
                  <Route path="/clients/lightspeed-auth-landing" element= {<LightspeedAuthLanding /> } />
              </Route>
              <Route element={<ClientMiddleware />} >
                <Route element={<PermissionMiddleware permissions={['add-client']} />}>
                  <Route path="/client/:client_code/setup" element={<ClientSetup />} />
                </Route>
                <Route path="/clients/:client_code/planninig/:clientId/preview" element={<ClientPlanPreview />} />
                <Route path="/clients/:client_code/kpi/:clientId" element={<ClientKPI />} />
                <Route path="/clients/:client_code/analytics/:clientId" element={<ClientAnalytics />} />
                {process.env.REACT_APP_REPORT_LOCKOUT_CURRENT_MONTH ?
                  <Route element={<PermissionMiddleware permissions={['role-create']} />}> {/* Temporary lockout from report for all non admin users when lockout env true */}
                    <Route path="/clients/:client_code/report-library/:clientId" element={<ClientReportLibrary />} />
                  </Route>
                : <Route path="/clients/:client_code/report-library/:clientId" element={<ClientReportLibrary />} /> }
                <Route path="/clients/:client_code/upload-data" element={<UploadData />} />
                <Route path="/clients/:client_code/profile" element={<ClientProfile />} />
                <Route element={<PermissionMiddleware permissions={['full-planning']} />}>
                  <Route path="/clients/:client_code/plan/locationclasses/:location_class_id" element={<BasePlan />} />
                  <Route path="/clients/:client_code/plan-future-base/locationclasses/:location_class_id" element={<BasePlan type='future' />} />
                  <Route path="/clients/:client_code/live-plan/:plan_id/:plan_version" element={<LivePlanModify />} />
                  <Route path="/clients/:client_code/plan/other-tools" element={<OtherPlanTools />} />
                </Route>
                <Route element={<PermissionMiddleware permissions={['view-client-info']} />}>
                  <Route path="/clients/:client_code/locations" element={<ClientLocations />} />
                  <Route path="/clients/:client_code/classes" element={<ClientClasses />} />
                  <Route path="/clients/:client_code/categories" element={<ClientCategories />} />
                  <Route path="/clients/:client_code/import-class" element={<ImportClass />} />
                  <Route path="/clients/:client_code/pos-mapping" element={<ClientPosMapping />} />
                </Route>
                <Route element={<PermissionMiddleware permissions={['full-planning']} />}>
                  <Route path="/clients/:client_code/tags" element={<ClientTags />} />
                  <Route path="/clients/:client_code/master-class" element={<ClientMasterClassSetting />} />
                  {/* <Route path="/clients/:client_code/sync" element={<ClientSync />} /> */}
                </Route>
              </Route>
              <Route element={<PermissionMiddleware permissions={['dev-tools']} />}>
                  <Route path="/dev-tools" element={<DevTools />} />
              </Route>
            </Route>
          </Route>
          <Route path="*" element={<NotFound />}/>
        </Routes>
      </CurrentClientContext.Provider>
      </HotKeysContext.Provider>
      </GlobalTip.Provider>
      </ConfirmDialogProvider>
      </AuthContext.Provider>
      </ThemeProvider>
    </>
  )
}

export default App
