import { useState, useEffect } from 'react';
import {
	Box,
	Typography,
	Container,
	Grid,
	Paper,
	Button,
	IconButton,
	Table,
	TableRow,
	TableContainer,
	TableCell,
	TableBody,
	TableHead,
	Chip,
	ToggleButtonGroup,
	ToggleButton,
	FormControlLabel,
	Checkbox,
	TextField,
} from '@mui/material';
import {
	Navigate,
	useParams,
	useNavigate,
	useSearchParams,
	Link,
} from "react-router-dom";
import { LoadingButton } from '@mui/lab'
import SectionTitle from '../../components/common/SectionTitle'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import PlanService from '../../services/PlanService';
import TipContext from '../../providers/GlobalTip';
import { handleTrackError, trackEvent } from '../../services/AmplitudeService';
import CurrentClientProvider from '../../providers/CurrentClientProvider';

function CopyPlanUpload(props) {
	const currentClient = CurrentClientProvider.useClient()
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const [months, setMonths] = useState([]);
	const [file, setFile] = useState()
	const [mode, setMode] = useState(searchParams.get('mode') ?? 'upload')
	const [form, setForm] = useState({
		source_locationclassid: '',
		source_epochmonth: '',
		destination_locationclassid: '',
		destination_epochmonth: '',
		ar_override: '',
		md_override: '',
		imu_override: '',
		turn_override: '',
		sales_model_type: '',
		imu_model_type: '',
		future_plan: false,
	});
	const [submiting, setSubmiting] = useState(false)
	const tipContext = TipContext.useMessage()

	useEffect(() => {
		getMonths()
	}, [])

	const formUpdate = (key, value) => {
		setForm({
			...form,
			[key]: value
		})
	}
	const tableauUrl = 'https://reports.retailorbit.com/t/ManagementOne/views/ClassListbyPlannerSF/Dashboard1?:embed=y&:showVizHome=no&:host_url=https%3A%2F%2Freports.retailorbit.com%2F&:embed_code_version=3&:tabs=no&:toolbar=yes&:showAppBanner=false&:display_spinner=no&:loadOrderID=0';

	const getMonths = async() => {
		const _d = await PlanService.getRecentEpochmonth()
		setMonths(_d)
	}

	const uploadFile = async() => {
		try {
			setSubmiting(true)
			const _file = await PlanService.uploadCopyPlanFile(file)
			navigate(`/plan/copy/file/${_file.id}`)
			tipContext.showSuccess()

			trackEvent('Copy Plan Event', {
				action: 'Upload Copy Plan File',
				clientCode: currentClient?.ClientCode
			})
		} catch(e) {
			tipContext.show(e.response?.data.message, 10)

			handleTrackError({ error: e, pageName: 'Copy Plan Upload', additionalMetadata: { clientCode: currentClient?.ClientCode, action: 'Upload Copy Plan File' } })
		}
		setSubmiting(false)
	}

	const submitLine = async() => {
		try {
			setSubmiting(true)
			const _file = await PlanService.createCopyPlanLine(form)
			navigate(`/plan/copy/line/${_file.id}`)
			tipContext.showSuccess()

			trackEvent('Copy Plan Event', {
				action: 'Submit Copy Plan Line',
				clientCode: currentClient?.ClientCode
			})
		} catch(e) {
			tipContext.show(e.response?.data.message)

			handleTrackError({ error: e, pageName: 'Copy Plan Line', additionalMetadata: { clientCode: currentClient?.ClientCode, action: 'Submit Copy Plan Line' } })
		}
		setSubmiting(false)
	}

	return <Box sx={{py:2}}>
		<Typography variant="h5">File Upload & Add Line</Typography>
		<Grid container spacing={2} sx={{mt: 1}}>
			<Grid item xs={12} sm={2}>
				<Paper elevation={2}>
					<Table size="small">
						<TableHead>
							<TableRow>
								<TableCell>Month</TableCell>
								<TableCell align='right'>Epochmonth</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{months.map(item => (
								<TableRow key={`month_${item.epochmonth}`} sx={{backgroundColor: item.current ? 'text.disabled' : ''}}>
									<TableCell>{item.date.format('MMM')}</TableCell>
									<TableCell align='right'>{item.epochmonth}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</Paper>
			</Grid>
			<Grid item xs={12} sm={10}>
				<ToggleButtonGroup
					value={mode}
					exclusive
					color="primary"
					onChange={(e, value) => value && setMode(value)}
				>
					<ToggleButton value="upload" >
						<UploadFileOutlinedIcon /> Upload File
					</ToggleButton>
					<ToggleButton value="line" >
						<AddCircleOutlineOutlinedIcon /> Add Line
					</ToggleButton>
				</ToggleButtonGroup>

				{mode=='upload' && (
					<Box sx={css.uploadSection}>
						<Box sx={css.inputSection}>
							{!file && (
								<>
									<CloudUploadIcon sx={{fontSize: 50}}/>
									<Typography> Drag and Drop a file here or click</Typography>
								</>
							)}
							{file && (
								<>
									<DescriptionOutlinedIcon sx={{fontSize: 50}}/>
									<Typography>{file.name}</Typography>
								</>
							)}
							<Box 
								component="input" 
								type="file" 
								name="file" 
								accept=".csv"
								onChange={e => setFile(e.target.files[0])}
							/>
						</Box>
						<LoadingButton loading={submiting} color="primary" variant="contained" size="large" sx={{mt: 2}} onClick={uploadFile}>Upload</LoadingButton>
						<Button component="a" href={`/templates/copy_plans_template.csv`} target="_blank" size="large" sx={{mt: 2, ml: 1}}>Download Template</Button>
					</Box>
				)}
				
				{mode=='line' && (
					<Box sx={css.formSection}>
						<TextField type="number" size="small" value={form.source_locationclassid} onChange={e => formUpdate('source_locationclassid', e.target.value)} label="Source Location Class ID" required/>
						<TextField type="number" size="small" value={form.source_epochmonth} onChange={e => formUpdate('source_epochmonth', e.target.value)} label="Source Epochmonth" required/>
						<TextField type="number" size="small" value={form.destination_locationclassid} onChange={e => formUpdate('destination_locationclassid', e.target.value)} label="Destination Location Class ID" required/>
						<TextField type="number" size="small" value={form.destination_epochmonth} onChange={e => formUpdate('destination_epochmonth', e.target.value)} label="Destination Epochmonth"/>
						<TextField type="number" size="small" value={form.ar_override} onChange={e => formUpdate('ar_override', e.target.value)} label="AR"/>
						<TextField type="number" size="small" value={form.md_override} onChange={e => formUpdate('md_override', e.target.value)} label="MD"/>
						<TextField type="number" size="small" value={form.imu_override} onChange={e => formUpdate('imu_override', e.target.value)} label="IMU"/>
						<TextField type="number" size="small" value={form.turn_override} onChange={e => formUpdate('turn_override', e.target.value)} label="Turn"/>
						<TextField type="number" size="small" value={form.sales_model_type} onChange={e => formUpdate('sales_model_type', e.target.value)} label="Sales Model"/>
						<TextField type="number" size="small" value={form.imu_model_type} onChange={e => formUpdate('imu_model_type', e.target.value)} label="IMU Model"/>
						<Box sx={{display:'flex', alignItems:'center',}}>
							<FormControlLabel control={<Checkbox checked={form.future_plan} onChange={e => formUpdate('future_plan', e.target.checked)} />} label="Future Plan" />
							<LoadingButton loading={submiting} color="primary" variant="contained" size="large" onClick={submitLine}>Submit</LoadingButton>
						</Box>
					</Box>
				)}

			</Grid>
			<Grid item xs={12}>
				<Box sx={css.iframe} component="iframe" src={tableauUrl}></Box>
			</Grid>
		</Grid>
		
	</Box>	
}

export default CopyPlanUpload

const css = {
	inputSection: {
		width: '500px',
		height: '185px',
		border: '3px dashed #ddd',
		borderRadius: 2,
		display: 'flex',
		flexDirection: "column",
		alignItems: 'center',
		justifyContent: 'center',
		position: 'relative',
		cursor: 'pointer',
		transition: 'all 0.3s',
		':hover': {
			backgroundColor: '#ddd',
		},
		'input': {
			position: 'absolute',
		    top: '0',
		    right: '0',
		    bottom: '0',
		    left: '0',
		    height: '100%',
		    width: '100%',
		    opacity: '0',
		    cursor: 'pointer',
		    zIndex: 5,
		}
	},
	iframe: {
		height: '710px',
		width: "100%",
		backgroundColor: '#eee',
		border: 'none',
	},
	uploadSection: {
		mt: 2,
	},
	formSection: {
		mt: 2,
		p: 2,
		borderRadius: 1,
		backgroundColor: 'white',
		'.MuiTextField-root': {
			mr: 1,
			mb: 1,
		}
	},
}